module.exports = {
  url: "https://tramango.com/",
  title: "Tramango.com: Buy Cheap Flights & Travel Insurance.",
  InsuranceTitle:
    "Tramango.com: Buy Baggage Protection, Medical Insurance, Premium Travel Insurance.",
  InsuranceDescription:
    "Guard against loss, emergencies and cancellations when you travel with Tramango’s Premium Travel Insurance services",
  description:
    "Book affordable flights & travel insurance on Tramango.com! Find the cheapest fares anywhere you want to go across the globe.",
  twitter: "@Tramangosocial",
  // cover_image: "https://tramango.com/public/images/seo-image.png",
  // cover_image_alt: "Tramango's cover image",
};
