import Head from "next/head";
import PropTypes from "prop-types";
import config from "../website.config";

function DocHead(props) {
  return (
    <Head>
      {/* favicon */}
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />

      {/* Title */}
      <title>{props?.title}</title>

      {/* SEO Meta Tags */}
      {/* Primary Meta Tags */}
      <meta name="title" content={props?.title} />
      <meta name="description" content={props?.description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      {/* Open Graph / Facebook Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" href={props?.url} />
      <meta property="og:title" content={props?.title} />
      <meta property="og:description" content={props?.description} />
      {props?.imageUrl && (
        <meta property="og:image" content={props?.imageUrl} />
      )}
      {props?.imageAlt && (
        <meta property="og:image:alt" content={props?.imageAlt} />
      )}

      {/* Twitter Meta Tags */}
      <meta
        name="twitter:card"
        content={props?.imageUrl ? "summary_large_image" : "summary"}
      />
      <meta name="twitter:site" content={config?.twitter} />
      <meta name="twitter:creator" content={config?.twitter} />
      <meta name="twitter:title" content={props?.title} />
      <meta name="twitter:description" content={props?.description} />
      {props?.imageUrl && (
        <meta property="twitter:image" content={props?.imageUrl} />
      )}
      {props?.imageAlt && (
        <meta property="twitter:image:alt" content={props?.imageAlt} />
      )}
    </Head>
  );
}
DocHead.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
};
DocHead.defaultProps = {
  imageUrl: "",
  imageAlt: "",
};
export default DocHead;
