import React from "react";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import support from "../../public/images/icons/24_support.svg";
import request from "../../public/images/icons/request_refund.svg";
import visa from "../../public/images/icons/visa_application.svg";
import styles from "./styles.module.scss";

function CustomerSupport() {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="text-2xl sm:text-3xl font-[700]">{t("got_questions")}</h2>
      <p>{t("enjoySeamless")}</p>
      <div className={styles.customerSupport}>
        <div className={styles.link}>
          <div>
            <Image src={support} alt="support icon" width={100} height={100} />
          </div>
          <div className={styles.info}>
            <h4>{t("247support")}</h4>
            <p>{t("247supporttext")}</p>
          </div>
        </div>
        <div className={styles.link}>
          <div>
            <Image
              src={request}
              alt="cancel request icon"
              width={100}
              height={100}
            />
          </div>
          <div className={styles.info}>
            <h4>{t("cancelBooking")}</h4>
            <p>{t("cancelBookingtext")}</p>
          </div>
        </div>
        <div className={styles.link}>
          <div>
            <Image
              src={visa}
              alt="visa application icon"
              width={100}
              height={100}
            />
          </div>
          <div className={`${styles.info}`}>
            <h4>{t("requestRefund")}</h4>
            <p>{t("requestRefundtext")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerSupport;
