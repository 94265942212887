export const formatNGN = (money) => {
  // formats price to naira, no kobo is added
  // eg. 1234.50 becomes NGN1234
  // eg. 5678.00 becomes NGN5678
  const newMoney = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  })
    .format(money)
    .slice(0, -3);
  return newMoney;
};
