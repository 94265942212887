import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { Subscribe } from "../../features/auth/authThunk";

function NewsletterForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const handleSubscribe = (e) => {
    e.preventDefault();
    dispatch(Subscribe({ email }));
  };
  return (
    <div className={styles.subscribeSection}>
      <div className={styles.info}>
        <h2 className="text-2xl sm:text-3xl font-[700]">{t("innerCircle")}</h2>
        <p>{t("subscribeTravel")}</p>
      </div>
      <form onSubmit={handleSubscribe} className={styles.subEmail}>
        <input
          className={styles.subscribeInput}
          placeholder={t("enterYourEmail")}
          name="EMAIL"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" className={styles.subscribeButton}>
          {t("subscribe")}
        </button>
      </form>
    </div>
  );
}

export default NewsletterForm;
