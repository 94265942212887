import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Image from "next/image";

function Affiliations({ affiliatedCompaniesCloud }) {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const keyframesAnimation = `
    @keyframes slideIn {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `;

  // const keyframesAnimation = `
  //   @keyframes slideIn {
  //     to {
  //       transform: translate(calc(-50% - 8px));
  //     }
  //   }
  // `;

  return (
    <>
      <h2 className=" max-w-full text-center">{t("ourAffiliations")}</h2>

      <div className=" flex w-full justify-center ">
        <div
          style={{
            mask: "linear-gradient(90deg, transparent, white 10%, white 90%, transparent)",
            WebkitMask:
              "linear-gradient(90deg, transparent, white 10%, white 90%, transparent)",
          }}
          className=" scroller max-w-[600px] overflow-hidden "
        >
          <style>{keyframesAnimation}</style>
          <div
            style={{
              animation: "slideIn 60s linear infinite", // inline style for the animation
            }}
            className="tag-list m-0 p-0 list-none scroller_inner w-max py-4 flex-nowrap flex gap-4 "
          >
            {affiliatedCompaniesCloud?.map(
              (company, index) =>
                index % 2 === 0 && (
                  <div
                    key={index}
                    className=" p-4  flex shrink-0 rounded-[8px] cursor-pointer "
                  >
                    <Image
                      src={
                        hoveredIndex === index
                          ? affiliatedCompaniesCloud[index + 1]
                          : company
                      }
                      alt=""
                      className=" min-h-[140px] min-w-[180px] object-contain"
                      height={56}
                      width={150}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    />
                  </div>
                )
            )}
            {affiliatedCompaniesCloud?.map(
              (company, index) =>
                index % 2 === 0 && (
                  <div
                    key={index}
                    className=" p-4  flex shrink-0 rounded-[8px] cursor-pointer "
                  >
                    <Image
                      src={
                        hoveredIndex === index
                          ? affiliatedCompaniesCloud[index + 1]
                          : company
                      }
                      alt=""
                      className=" min-h-[140px] min-w-[180px] object-contain"
                      height={56}
                      width={150}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    />
                  </div>
                )
            )}
            {affiliatedCompaniesCloud?.map(
              (company, index) =>
                index % 2 === 0 && (
                  <div
                    key={index}
                    className=" p-4  flex shrink-0 rounded-[8px] cursor-pointer "
                  >
                    <Image
                      src={
                        hoveredIndex === index
                          ? affiliatedCompaniesCloud[index + 1]
                          : company
                      }
                      alt=""
                      className=" min-h-[140px] min-w-[180px] object-contain"
                      height={56}
                      width={150}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
}
Affiliations.propTypes = {
  affiliatedCompaniesCloud: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
};
export default Affiliations;
